import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';

// ----------------------------------------------------------------------
const link1 = '/#websection';
const baseUrl = window.location.origin.toString();
const url = baseUrl + link1;
const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    path: '/',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Features',
    path: url,
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Docs',
    path: 'https://docs.backed.capital/',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  {
    title: 'Contact',
    path: '#footer',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  }
];

export default menuConfig;
